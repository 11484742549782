import {useEffect, useState} from "react";
import {viewManager} from "../../../service/view/manager";
import {Badge, Tooltip} from "antd";
import {DesktopOutlined, SettingOutlined, PlusOutlined} from '@ant-design/icons';


const WindowNum = () => {

    const [windowNum, setWindowNum] = useState(0)

    useEffect(() => {
        viewManager.getViewId()
            .subscribe(viewNum => {
                setWindowNum(viewNum)
            })
    }, [])

    return (
        <div>
            <Tooltip title={'Open new window'}>
                <Badge size="small" count={windowNum !== 0 ? windowNum : 0} showZero={false} style={{ backgroundColor: 'rgb(220, 136, 174)'}}>
                    <DesktopOutlined style={{ fontSize: '24px', verticalAlign: 'middle'}}
                        // TODO - this doesn't actually work how we want it to yet
                         onClick={() => {window.open(window.location.origin, '', 'width=1000,height=700')}}
                    />
                </Badge>
            </Tooltip>

        </div>
    )

}

export default WindowNum