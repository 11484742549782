import React, {useEffect, useRef, useState} from 'react';
import {Layout, Menu, Row} from 'antd';
import {UserOutlined, TeamOutlined, DesktopOutlined} from '@ant-design/icons';
import {useHistory} from 'react-router';
import {Route, Switch} from "react-router-dom";
import UserConfigForm from "./user/userConfig";
import DisplayConfigForm from "./user/displayConfig";

const {Content} = Layout;

const Settings = () => {
    const history = useHistory();

    var lastSubPath = ''
    const updateSubPath = () => {
        if (history.location.pathname.startsWith("/settings/")) {
            lastSubPath = history.location.pathname
        }
    }
    updateSubPath()

    const getSelected = useRef(() => {
        const path = history.location.pathname
        if (path.includes('organization')) {
            return ['organization']
        } else if (path.includes('user')) {
            return ['user']
        } else if (path.includes('display')) {
            return ['display']
        } else if (path === '/settings') {
            // Re-route /settings to default of user or stay on last sub nav spot
            if (lastSubPath.startsWith('/settings')) {
                history.push(lastSubPath)
            } else {
                history.push('/settings/user')
            }
        }
    })

    // Select proper menu item
    const [selectedKeys, setSelectedKeys] = useState(getSelected.current());
    history.listen((event: any) => {
        if (history.location.pathname.startsWith("/settings")) {
            setSelectedKeys(getSelected.current())
            updateSubPath()
        }
    })

    useEffect(() => {
        setSelectedKeys(getSelected.current())
    }, [])


    return (
        <>
            <Row>
                <Menu mode="horizontal"  selectedKeys={selectedKeys} style={{width: "100%"}}>
                    <Menu.Item key="user" icon={<UserOutlined/>} onClick={() => history.push('/settings/user')}>
                        User
                    </Menu.Item>
                    <Menu.Item key="organization" icon={<TeamOutlined/>} onClick={() => history.push('/settings/organization')}>
                        Organization
                    </Menu.Item>
                    <Menu.Item key="display" icon={<DesktopOutlined/>} onClick={() => history.push('/settings/display')}>
                        Display
                    </Menu.Item>
                </Menu>
            </Row>
            <Row>

                <Content>
                    <Switch>
                        <Route path="/settings/user" component={UserConfigForm}/>
                        <Route path="/settings/display" component={DisplayConfigForm}/>
                        {/*<Route path="/settings/accounts" component={AccountsConfig}/>*/}
                    </Switch>

                </Content>

            </Row>
        </>


    );

}

export default Settings;