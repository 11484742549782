import React from 'react';
import ReactDOM from 'react-dom';
import LoginPage from "./components/pages/user/login";
import {session} from "./service/session";
import {AuthenticationResult} from "./service/domain/authenticationResult";
import ApplicationRoutes from "./config/AppRoutes";
import 'antd/dist/antd.dark.css';


export function launchApp() {
    ReactDOM.render(
      <React.StrictMode>
        <ApplicationRoutes />
      </React.StrictMode>,
      document.getElementById('root')
    );
}

session.authenticateExistingSession(started => {
    if (started === AuthenticationResult.SUCCESS) {
        launchApp()
    } else {
        ReactDOM.render(
            <React.StrictMode>
                <LoginPage />
            </React.StrictMode>,
            document.getElementById('root')
        );
    }
})