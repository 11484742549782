import axios from 'axios';
import {from, Observable, of, throwError} from "rxjs";
import {map, mergeMap} from 'rxjs/operators';
import {Session, User, UserSettings} from "./domain/user";


interface ResponseHandler<T> {
    onResult(result: T): void;
    onError(errorCode: number, message: string, data: any): void;
}

/**
 * Designed to support being used as a string for backward compatibility with lots of code that
 * assumed the error from a request is a string.
 */
class JsonRpcError extends String {
    code: number;
    message: string;
    data: any;

    constructor (code: number, message: string, data: any) {
        super(message);
        this.code = code
        this.message = message
        this.data = data
    }

}

class RpcClient {

    private HOST = process.env.REACT_APP_HOST ? process.env.REACT_APP_HOST : "http://localhost:8080";
    private requestId = 1;

    public sendBeacon(method: string, params: object = {}) {
        const request = {
            jsonrpc: '2.0',
            id: ++this.requestId,
            method,
            params
        }
        navigator.sendBeacon(this.HOST, JSON.stringify(request))
    }

    public sendRequest<T>(method: string, params: object = {}): Observable<T> {
        const request = {
            jsonrpc: '2.0',
            id: ++this.requestId,
            method,
            params
        }
        // console.log("SENDING REQUEST: " + JSON.stringify(request, null, 2))
        return from(axios.post(this.HOST, request)) // TODO - This isn't actually reactive, sends request immediately
            .pipe(mergeMap(response => {
                if ('result' in response.data) {
                    return of(response.data.result);
                } else {
                    return throwError(new JsonRpcError(response.data.error.code, response.data.error.message,
                        response.data.error.data ? response.data.error.data : undefined));
                }
            }))
    }

    public sendRequestAsync<T>(method: string, params: object = {}, handler: ResponseHandler<T>): void {
        const request = {
            jsonrpc: '2.0',
            id: ++this.requestId,
            method,
            params
        }
        axios.post(this.HOST, request)
            .then(response => {
                if ('result' in response.data) {
                    handler.onResult(response.data.result as T)
                } else {
                    handler.onError(response.data.error.code, response.data.error.message, response.data.error.data)
                }
            })
    }

}

class ApiClient extends RpcClient {

    private static singletonInstance: ApiClient;

    public getTime(): Observable<Date> {
        return this.sendRequest<string>("time")
            .pipe(map(result => {
                return new Date(result)
            }))
    }

    public createUser(email: string, password: string, name: string): Observable<boolean> {
        return this.sendRequest<boolean>("createUser", {email, password, name})
    }

    public resetPassword(email: string): Observable<boolean> {
        return this.sendRequest<boolean>("resetPassword", {email})
    }

    public authenticateUser(email: string, password: string, handler: ResponseHandler<string>) {
        return this.sendRequestAsync<string>("authenticateUser", {email, password}, handler)
    }

    public getUserSession(sessionAuthToken: string): Observable<Session> {
        return this.sendRequest<Session>("getUserSession", {sessionAuthToken})
    }

    public getUser(sessionAuthToken: string): Observable<User> {
        return this.sendRequest<User>("getUser", {sessionAuthToken})
    }

    public updateUser(sessionAuthToken: string, email: string | null, name: string | null, settings: UserSettings | null): Observable<User> {
        let output = {} as User;
        if (email !== null)
            output.email = email;
        if (name !== null)
            output.name = name;
        if (settings !== null)
            output.settings = settings
        return this.sendRequest<User>("updateUser", {...output, sessionAuthToken})
    }

    public updatePassword(sessionAuthToken: string, oldPassword: string, newPassword: string): Observable<boolean> {
        return this.sendRequest<boolean>("updatePassword", {oldPassword, newPassword, sessionAuthToken})
    }

    public static get Instance() {
        return this.singletonInstance || (this.singletonInstance = new this());
    }

}

export default ResponseHandler;
export const api = ApiClient.Instance;