import {Observable} from "rxjs";
import {api} from "../apiClient";
import {session} from "../session";

export interface Params {
    command: string,
    entities: string[],
}

class Commands {

    public submitCommand(input: string): Observable<Params> {
        return api.sendRequest<Params>("submitCommand", {
            sessionAuthToken: session.getSessionAuthToken(),
            input: input
        })
    }

    private static singletonInstance: Commands

    public static get Instance() {
        return this.singletonInstance || (this.singletonInstance = new this())
    }

}

export const commands = Commands.Instance