import React, {useEffect, useLayoutEffect, useState} from 'react';
import {BrowserRouter as Router, Link, Redirect, Route, Switch} from 'react-router-dom';
import {Button, Col, Layout, Row, Tooltip} from 'antd';
import {LogoutOutlined, SettingOutlined} from '@ant-design/icons';
import {session} from "../service/session";
import Counter from "../components/pages/temp/counter";
import Settings from "../components/pages/settings";
import WindowNum from "../components/pages/temp/windowNum";
import {api} from "../service/apiClient";
import {map} from "rxjs/operators";
import {UserSettings} from "../service/domain/user";
import TerminalInput from "../components/terminalInput";
import LoadingPage from "../components/pages/loading";


const { Header, Content, Footer} = Layout;

const shortcutIconStyles = {
    marginLeft: 8
}


function ApplicationRoutes() {
    
    useEffect(() => {
        api.getUser(session.getSessionAuthToken())
            .pipe(map(user => user.settings as UserSettings))
            .subscribe((settings) => {
                // switcher({theme: settings.darkMode ? themes.dark : themes.light})
                if (settings.darkMode) {
                    // @ts-ignore
                    import('../styles/temp/antd.dark.css'); // dark mode
                } else {
                    // @ts-ignore
                    import('../styles/temp/antd.css'); // light mode
                }
            })
    }, [])

    // Listen to window resizing so can update layout properly on resize
    const [windowWidth, setWindowWidth] = useState(0);
    useLayoutEffect(() => {
        function updateSize() {
            setWindowWidth(window.innerWidth);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);


    return (
        <Router>
                <Layout >
                    <Header style={{padding: 0}}>
                        <Row style={{maxWidth: '1000px', margin: 'auto'}}>
                            <Col offset={0} span={1} style={{textAlign:'left'}}>
                                <WindowNum/>
                            </Col>
                            <Col offset={0} span={11} style={{textAlign:'left'}}>
                                <TerminalInput/>
                            </Col>
                            <Col span={11} style={{textAlign: 'right'}}>
                                <Tooltip title={"Settings"}>
                                    <Link to={"/command/SETTINGS"}>
                                        <Button type="default" shape="circle" icon={<SettingOutlined/>} style={shortcutIconStyles}/>
                                    </Link>
                                </Tooltip>
                                <Tooltip title={"Log out"}>
                                    <Link to={"/command/OFF"}>
                                    {/*TODO - Broadcast this to all open tabs!*/}
                                        <Button type="default" shape="circle" icon={<LogoutOutlined/>} style={shortcutIconStyles}/>
                                    </Link>
                                    </Tooltip>
                            </Col>
                        </Row>
                    </Header>
                    <Row style={windowWidth > 1200 ? { // TODO - play around with these min/max width values and set finally
                        maxWidth: '1200px', minWidth: '1000px', marginLeft: 'auto', marginRight: 'auto'} :
                        {}}>
                        <Col span={24}>
                            <Content style={{
                                padding: 24,
                                minHeight: "calc(100vh - 132px)",
                            }}>
                                <Switch>
                                    <Route path="/settings" component={Settings}/>
                                    <Route path="/counter" component={Counter}/>
                                    <Route path="/command" component={LoadingPage}/>
                                    <Redirect to="/counter" from="/"/>
                                </Switch>

                            </Content>
                        </Col>
                    </Row>
                    {/*<Footer style={{}}>*/}
                    {/*    <Row style={{maxWidth: '1000px', marginLeft: 'auto', marginRight: 'auto'}}>*/}
                    {/*        <WindowNum/>*/}
                    {/*    </Row>*/}
                    {/*</Footer>*/}
                </Layout>
        </Router>
    );
}

export default ApplicationRoutes;