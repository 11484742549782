import {useEffect, useRef, useState} from "react";
import {viewManager} from "../../../service/view/manager";
import {Button} from "antd";
import {finalize, map} from "rxjs/operators";

const Counter = () => {

    class Dynamic {
        count : number = 0
    }

    const sendDynamicUpdates = useRef<boolean>(false)
    const [viewId, setViewId] = useState<number>(0);
    const [dynamicInfo, setDynamic] = useState<Dynamic>(new Dynamic())

    const setDefault = useRef(() => {
        console.log("UPDATING DYNAMIC TO DEFAULTS")
        sendDynamicUpdates.current = true // want to update
        setDynamic(new Dynamic())
    })

    // Populate view info on load
    useEffect(() => {
        sendDynamicUpdates.current = false
        viewManager.getView({page: "count"})
            .pipe(map((viewInfo) => {
                setViewId(viewInfo.viewId)
                console.log(viewInfo.dynamicFields)
                // TODO - handle the empty dynamic field response better....
                Object.keys(viewInfo.dynamicFields).length === 0 ? setDefault.current() : setDynamic(viewInfo.dynamicFields as Dynamic)
            }))
            .pipe(finalize(() => {sendDynamicUpdates.current = true})) // update
            .subscribe()
    }, [])


    // on every dynamic info update, update the state on the server
    useEffect(() => {
        if (sendDynamicUpdates.current)
            viewManager.updateView({page:"count"}, dynamicInfo).subscribe()
    }, [dynamicInfo])

    const Increment = () => {
        setDynamic((prev) => ({
            ...prev,
            count: prev.count+1
        }))
    }
    const Decrement = () => {
        setDynamic((prev) => ({
            ...prev,
            count: prev.count-1
        }))
    }

    return (
        <>
            <div>
                The current view is {viewId}
            </div>
            <div>
                <Button onClick={Increment}>Increment Value</Button>
                <Button>{dynamicInfo.count}</Button>
                <Button onClick={Decrement}>Decrement Value</Button>
            </div>
        </>
    )
}

export default Counter