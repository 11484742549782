import ResponseHandler, {api} from "./apiClient";
import {deleteCookie, getCookie, setCookie} from "./util/cookies";
import {Session} from "./domain/user";
import assert from "assert";
import {AuthenticationResult} from "./domain/authenticationResult";

class SessionManager {

    private static COOKIE_NAME = "sessionAuthToken";
    private static COOKIE_DURATION_MS = 30 * 24 * 60 * 60 * 1000

    private session?: Session;

    /**
     * Creates a new session.
     *
     * @param email
     * @param password
     * @param remember true to store session auth token in cookies
     * @param callback true if authenticated
     */
    public authenticateNewSession(email: string, password: string, remember: boolean, callback: (started: AuthenticationResult) => void) {
        assert(this.session === undefined, "Authenticate should only be called once on session start")
        const parent = this
        api.authenticateUser(email, password, new class implements ResponseHandler<string> {
            onResult(sessionAuthToken: string): void {
                parent.session = {sessionAuthToken} as Session
                callback(AuthenticationResult.SUCCESS)
                if (remember) {
                    setCookie(SessionManager.COOKIE_NAME, sessionAuthToken, SessionManager.COOKIE_DURATION_MS)
                }
            }
            // depending on the error, set a different invalid, not just invalid password since that could
            // confuse people if it's the backend not responding
            onError(errorCode: number, message: string, data: any): void {
                if (errorCode === -32005) // denied response code
                    callback(AuthenticationResult.INVALID_CREDENTIALS)
                else
                    callback(AuthenticationResult.TIMEOUT)
            }
        }())
    }

    /**
     * Check if there is a cookie with existing session authentication info to bypass password login.
     * Starts session if there is one.
     *
     * @param callback true if authenticated
     */
    public authenticateExistingSession(callback: (started: AuthenticationResult) => void) {
        assert(this.session === undefined, "Authenticate should only be called once on session start")
        const parent = this
        let possibleSessionToken = getCookie(SessionManager.COOKIE_NAME)
        if (possibleSessionToken) {
            api.getUserSession(possibleSessionToken)
                .subscribe((session) => {
                    parent.session = session
                    callback(AuthenticationResult.SUCCESS)
                }, err => {
                    this.endSession()
                })
        } else {
            callback(AuthenticationResult.PENDING)
        }
    }

    public getSessionAuthToken(): string {
        return this.session?.sessionAuthToken as string
    }

    public endSession() {
        deleteCookie(SessionManager.COOKIE_NAME)
        window.location.reload()
    }

    private static singletonInstance: SessionManager;

    public static get Instance() {
        return this.singletonInstance || (this.singletonInstance = new this());
    }


}

export const session = SessionManager.Instance;