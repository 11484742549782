import React from "react";
import {Col, Row, Spin} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const LoadingPage = () => {

    const antIcon = <LoadingOutlined style={{ fontSize: 100 }} spin />;

    return (
        <>
            <Row>
                <Col span={24} style={{textAlign: 'center'}}>
                    <Spin indicator={antIcon} />
                </Col>
            </Row>
        </>
    )

}

export default LoadingPage;