import React from 'react';
import {Button, Form, Input, message, Modal} from 'antd';
import {FormInstance} from "antd/lib/form";
import {api} from "../../../service/apiClient";
import {finalize} from 'rxjs/operators';
import styled from "styled-components";

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};

type ForgotPasswordFormProps = {
    modal: ForgotPasswordModal
}

// Override default button text color to match jooni logo blue
const StyledButton = styled(Button)`
  color: rgb(114,126,239);
  text-align: right;
  margin-left: 27px;
  padding-right: 0px;
`;

const formRef = React.createRef<FormInstance>(); // Link form submit event to modal button
const ForgotPasswordForm = ({modal}: ForgotPasswordFormProps) => {
    const [form] = Form.useForm();

    // On valid form submission
    const onFinish = (values: any) => {
        modal.setState({confirmLoading: true});
        // TODO - handle server not responding/not connected
        api.resetPassword(values.email)
            .pipe(finalize(() => modal.setState({confirmLoading: false})))
            .subscribe((created: boolean) => {
                if (created) {
                    modal.setState({visible: false});
                    formRef.current!.resetFields();
                    message.success("Password reset")
                } else {
                    message.error("Password reset denied")
                }
            }, error => {
                message.error(error)
            })
    };

    return (
        <Form
            {...formItemLayout}
            form={form}
            name="Forgot Password"
            onFinish={onFinish}
            scrollToFirstError
            ref={formRef}
        >
            <Form.Item
                name="email"
                label="E-mail"
                rules={[
                    {
                        type: 'email',
                        message: 'Invalid E-mail',
                    },
                    {
                        required: true,
                        message: 'Please input your E-mail',
                    },
                ]}
            >
                <Input/>
            </Form.Item>
        </Form>
    );
}


class ForgotPasswordModal extends React.Component {

    state = {
        visible: false,
        confirmLoading: false
    };

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    handleOk = () => {
        formRef.current!.submit();
    };

    handleCancel = () => {
        formRef.current!.resetFields();
        this.setState({
            visible: false,
            confirmLoading: false
        });
    };

    render() {
        const { visible, confirmLoading} = this.state;
        return (
            <StyledButton type="link" onClickCapture={this.showModal}>
                Forgot Password?
                <Modal
                    title="Forgot Password"
                    visible={visible}
                    onOk={this.handleOk}
                    confirmLoading={confirmLoading}
                    onCancel={this.handleCancel}
                    footer={[
                        <Button key="back" onClick={this.handleCancel}>
                            Cancel
                        </Button>,
                        <Button key="submit" type="primary" loading={confirmLoading} onClick={this.handleOk}>
                            Submit
                        </Button>,
                    ]}
                >
                    <ForgotPasswordForm modal={this}/>
                </Modal>
            </StyledButton>
        );
    }
}

export default ForgotPasswordModal;