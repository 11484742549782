import React, {useState, useEffect} from "react";
import {Form, Input, Divider, Button, message, Spin} from "antd";
import {api} from "../../../service/apiClient";
import {session} from "../../../service/session";
import {finalize} from "rxjs/operators";

const layout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 14},
};

const dividerStyles = {
    paddingTop: '20px'
}

const sectionStyles = {
    paddingLeft: 50,
    maxWidth: 500
}

const UserConfigForm = () => {
    const token = session.getSessionAuthToken()
    const [infoForm] = Form.useForm();
    const [updatePasswordForm] = Form.useForm();
    const [infoFormLoading, setInfoFormLoading] = useState(false)
    const [state, setState] = useState({infoLoading: false, passwordLoading: false});


    // Populate user info on load
    useEffect(() => {
        setInfoFormLoading(true)
        api.getUser(token)
            .pipe(finalize(() => setInfoFormLoading(false)))
            .subscribe(user => {
                infoForm.setFieldsValue(user)
            }, error => {message.error(error)})
    }, [infoForm, token]);

    const onInfoSubmit = (values: any) => {
        setState({...state, ...{infoLoading: true}})
        api.updateUser(token, values.email, values.name, null)
            .pipe(finalize(() => setState({...state, ...{infoLoading: false}})))
            .subscribe(user =>{
                infoForm.setFieldsValue(user)
                message.success("Updated user information")
            }, error => {
                message.error(error)
            })
    }

    const onPasswordSubmit = (values: any) => {
        setState({...state, ...{passwordLoading: true}})
        api.updatePassword(token, values.oldPassword, values.confirm)
            .pipe(finalize(() => setState({...state, ...{passwordLoading: false}})))
            .subscribe((updated) => {
                if (updated) {
                    updatePasswordForm.resetFields();
                    message.success("Updated password")
                } else {
                    message.error("Password update failed")
                }
            }, error => {
                message.error(error)
            })
    }

    return (
        <>
            <Divider orientation="left" style={ dividerStyles}>Update User Information</Divider>

            <Form {...layout} style={sectionStyles}
                  form={infoForm}
                  name="userInfoForm"
                  onFinish={onInfoSubmit}
                  scrollToFirstError
            >
                <Spin spinning={infoFormLoading}>
                    <Form.Item
                        name="email"
                        label="E-mail"
                        rules={[
                            {
                                type: 'email',
                                message: 'Invalid E-mail',
                            },
                            {
                                required: true,
                                message: 'Please input your E-mail',
                            },
                        ]}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        name="name"
                        rules={[{required: true, message: 'Please input your name', whitespace: true}]}
                        label={"Name"}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 10 }}>
                        <Button type="primary" htmlType="submit" loading={state.infoLoading}>
                            Update User
                        </Button>
                    </Form.Item>

                </Spin>
            </Form>



            <Divider orientation="left" style={dividerStyles}>Update Password</Divider>
            <Form {...layout} style={sectionStyles}
                  form={updatePasswordForm}
                  name="updatePasswordForm"
                  onFinish={onPasswordSubmit}
                  scrollToFirstError
            >
                <Form.Item
                    name="oldPassword"
                    label="Previous Password"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your previous password',
                        },
                    ]}
                    hasFeedback
                >
                    <Input.Password/>
                </Form.Item>

                <Form.Item
                    name="newPassword"
                    label="New Password"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your new password',
                        },
                    ]}
                    hasFeedback
                >
                    <Input.Password/>
                </Form.Item>

                <Form.Item
                    name="confirm"
                    label="Confirm New Password"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Please confirm your new password',
                        },
                        ({getFieldValue}) => ({
                            validator(rule, value) {
                                if (!value || getFieldValue('newPassword') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject('The two new passwords that you entered do not match');
                            },
                        }),
                    ]}
                >
                    <Input.Password/>
                </Form.Item>

                <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 10 }}>
                    <Button type="primary" htmlType="submit" loading={state.passwordLoading}>
                        Update Password
                    </Button>
                </Form.Item>

            </Form>

        </>
    )
}

export default UserConfigForm