import {Divider, Form, message, Spin, Switch} from "antd";
import {api} from "../../../service/apiClient";
import {session} from "../../../service/session";
import {useEffect, useState} from "react";
import {finalize} from "rxjs/operators";


const layout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 14},
};

const dividerStyles = {
    paddingTop: '20px'
}

const sectionStyles = {
    paddingLeft: 50,
    maxWidth: 500
}

const DisplayConfigForm = () => {

    const token = session.getSessionAuthToken()
    const [displayForm] = Form.useForm();
    const [loadingDarkSwitch, setLoadingDarkSwitch] = useState(false)
    const [displayFormLoading, setDisplayFormLoading] = useState(false)

    // Populate settings on load
    useEffect(() => {
        setDisplayFormLoading(true)
        api.getUser(token)
            .pipe(finalize(() => setDisplayFormLoading(false)))
            .subscribe((user) => {
                displayForm.setFieldsValue(user.settings)
            }, (error) => {message.error(error)})
    }, [displayForm, token])

    const onDarkSwitch = (value: boolean) => {
        setLoadingDarkSwitch(true)
        api.updateUser(token, null, null, {darkMode: value})
            .pipe(finalize(() => {
                setLoadingDarkSwitch(false)
                window.location.reload()
            }))
            .subscribe()
    }

    return (
        <>
            <Divider orientation="left" style={dividerStyles}>Update Display Configuration</Divider>

            <Form {...layout} style={sectionStyles}
                  form={displayForm}
                  name="userDisplayForm"
                  // onFinish={onSubmit}
            >
                <Spin spinning={displayFormLoading}>
                    <Form.Item
                        name="darkMode"
                        label="dark-mode"
                        >
                        <Switch onClick={onDarkSwitch} checked={displayForm.getFieldValue("darkMode")} loading={loadingDarkSwitch}/>
                    </Form.Item>
                </Spin>
            </Form>
        </>
    )
}

export default DisplayConfigForm